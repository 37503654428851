import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
          
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es__header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es__header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                 {/*<li>
                    <a className="es__header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/know_the_sun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/know_the_sun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/conozca-el-sol/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/sobre_o_sol/">PT</a>
                      </li>
                      <li>
                        <a href="/it/know_the_sun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/know_the_sun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="kts-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x337px_KnowTheSun.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/know_the_sun_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <div className="kts-page container">
              <div className="kts-page__intro">
                <h1>CONOZCA EL SOL</h1>
                <h3>NUESTRA GUÍA PARA DISFRUTAR DEL SOL DE FORMA SEGURA</h3>
                <p>
                  Creemos que toda información es poca cuando se trata del sol y
                  sus efectos potenciales sobre nosotros. A continuación
                  ofrecemos información importante sobre el sol y cómo disfrutar
                  de él de manera responsable.
                </p>
              </div>
              <section id="content">
                <div className="innerContent">
                  <ul className="ktsBlocks">
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">EL SOL</h2>
                        <p className="toggle__sub-title">
                          Las radiaciones UVA y UVB son términos que nos
                          resultan familiares, pero… ¿qué significan y cómo nos
                          afectan? Averígüelo aquí..{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul id="container_thesun">
                        <li>
                          <div
                            id="ccm-layout-wrapper-5722"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-4-1"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-1 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-4-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  <h2>RADIACIÓN</h2>
                                  <p className="p1">
                                    El sol proporciona a la Tierra la energía
                                    necesaria para la vida y es el principal
                                    factor que determina el clima y el tiempo
                                    del planeta. El sol no sólo envía luz
                                    visible a la Tierra, sino que también emite
                                    radiación invisible que puede tener un
                                    impacto negativo en nuestra salud si
                                    recibimos dosis demasiado altas. La
                                    radiación solar está formada por una amplia
                                    variedad de rayos. Algunos ni siquiera
                                    llegan a la Tierra porque los bloquea la
                                    capa de ozono. La radiación que alcanza la
                                    Tierra consiste en rayos visibles, rayos
                                    infrarrojos (IR) y rayos ultravioleta (UV).
                                    La radiación UV se divide a su vez entre los
                                    rayos UVA, UVB y UVC, en función de su
                                    longitud de onda. Los rayos UVC también los
                                    bloquea la capa de ozono.
                                  </p>
                                  <div
                                    id="blockStyle1681TheSunLayout1Cell162"
                                    className=" ccm-block-styles"
                                  >
                                    <img
                                      border="0"
                                      className="ccm-image-block"
                                      alt
                                      src="/assets/images/Radiation_graph_ES.png"
                                      width="640"
                                      height="380"
                                    />
                                  </div>
                                  <h2>LUZ VISIBLE</h2>
                                  <p>
                                    La luz visible comprende todos los colores
                                    que podemos ver a simple vista.
                                  </p>
                                  <p> </p>
                                  <h2>RAYOS INFRARROJOS (IR)</h2>
                                  <p>
                                    Los rayos infrarrojos (IR) transportan
                                    energía calorífica, que nos permite sentir
                                    el calor.
                                  </p>
                                  <p> </p>
                                  <h2>RAYOS ULTRAVIOLETA (UV)</h2>
                                  <p>
                                    Los rayos ultravioleta (UV) son invisibles y
                                    se dividen entre los rayos UVA, UVB y UVC.
                                    Los rayos UVA provocan daños en la piel a
                                    largo plazo, que incluyen cáncer de piel y
                                    envejecimiento prematuro de la misma,
                                    mientras que los rayos UVB son la principal
                                    causa de las quemaduras solares. A la
                                    superficie de la Tierra llegan
                                    aproximadamente 10 veces más rayos UVA que
                                    rayos UVB. Los rayos UVC son absorbidos por
                                    la atmósfera de la Tierra y no afectan a la
                                    piel.
                                  </p>
                                  <p>
                                    La intensidad de la radiación solar depende
                                    de diversos factores que incluyen: la época
                                    del año, hora del día, latitud, altitud,
                                    reflejos de la superficie terrestre y
                                    condiciones meteorológicas. Por ejemplo, el
                                    sol brilla más a mediodía y, a grandes
                                    alturas, la atmósfera es más fina y absorbe
                                    menos radiación UV.
                                  </p>
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="ccm-layout-wrapper-5723"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-8-3"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-3 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-8-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  &nbsp;
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1156TheSun83"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_01.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">El 80%</span> de los
                                  rayos UV puede traspasar las nubes
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1157TheSun91"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_02.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  La nieve limpia refleja hasta un{" "}
                                  <span className="orange">80%</span> de los
                                  rayos UV
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1158TheSun92"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_03.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">El 60%</span> de los
                                  rayos UV se produce entre las 10h y las 14h
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1159TheSun93"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_04.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  La radiación UV aumenta un{" "}
                                  <span className="orange">4%</span> por cada{" "}
                                  <span className="orange">300</span> metros de
                                  altitud
                                </p>
                                <div> </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1160TheSun94"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_05.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  La sombra puede reducir más del{" "}
                                  <span className="orange">50%</span> la
                                  exposición a los rayos UV
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1161TheSun95"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_06.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A medio metro bajo el agua, los rayos UV aún
                                  tienen una intensidad del{" "}
                                  <span className="orange">40%</span> respecto a
                                  la superficie
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1162TheSun96"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_07.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  La arena blanca refleja hasta el{" "}
                                  <span className="orange">15%</span> de los
                                  rayos UV
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1163TheSun97"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_08.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  El agua refleja cerca del{" "}
                                  <span className="orange">25%</span> de la
                                  radiación UV
                                </p>
                              </div>
                            </div>
                          </div>
                          <h2>EFECTOS DEL SOL</h2>
                          <p>
                            El sol permite que la vida sea posible en la Tierra,
                            pero también puede tener efectos nocivos,
                            especialmente si no nos protegemos suficientemente.
                          </p>
                          <p> </p>
                          <h2>EFECTOS INMEDIATOS</h2>
                          <p>
                            <strong>Pigmentación inmediata</strong>
                            <br />
                            El primer efecto de coloración provocado por la
                            exposición al sol se conoce como “pigmentación
                            inmediata”: es el color que aparece durante la
                            exposición y desaparece en dos horas. Provocada por
                            los rayos UVA y la luz visible, la pigmentación
                            inmediata no protege contra las quemaduras solares.
                          </p>
                          <p> </p>
                          <p>
                            <strong>
                              Oscurecimiento pigmentario persistente (PPD)
                            </strong>
                          </p>
                          <p>
                            El PPD aparece durante la exposición al sol y
                            permanece durante unos cuantos días. No protege la
                            piel.
                          </p>
                          <p> </p>
                          <h2>EFECTOS RETARDADOS</h2>
                          <p>
                            <strong>Quemadura solar</strong>
                            <br />
                            Si no se protege de las altas dosis de rayos UV,
                            estos empezarán a penetrar las capas de la piel.
                            Después, los vasos sanguíneos que hay debajo de la
                            superficie de la piel se expandirán y verterán
                            fluidos, provocando inflamación y enrojecimiento. La
                            quemadura solar aparece normalmente entre las 2–6
                            horas después del inicio de la exposición,
                            alcanzando su intensidad máxima a las 24–36 horas, y
                            desaparece después de 3–5 días.
                          </p>
                          <p> </p>
                          <p>
                            <strong>Bronceado (Pigmentación retardada)</strong>
                            <br />
                            El bronceado empieza de 3 a 5 días después de la
                            exposición al sol, cuando determinadas células de la
                            piel llamadas melanocitos, comienzan a sintetizar la
                            melanina, el pigmento bronceador natural de la piel.
                            A continuación, la melanina se transfiere a unas
                            células próximas denominadas queratinocitos, el
                            principal tipo de célula de la epidermis o capa
                            superficial de la piel. A diferencia de la
                            pigmentación inmediata, este tipo de bronceado ayuda
                            a proteger contra los efectos nocivos de la
                            radiación UV. Aunque el bronceado desaparece
                            progresivamente, recuerde que las personas con un
                            bronceado natural también deben utilizar un
                            protector solar, ya que el bronceado natural no
                            protege suficientemente contra la exposición a los
                            rayos UV.
                          </p>
                          <p> </p>
                          <h2>EFECTOS A LARGO PLAZO</h2>
                          <p>
                            <strong>Envejecimiento de la piel</strong>
                            <br />
                            La exposición al sol juega un papel fundamental en
                            el proceso de envejecimiento de la piel. Hasta el
                            90% de los signos visibles del envejecimiento se
                            atribuyen a los efectos nocivos de la exposición al
                            sol.
                          </p>
                          <p> </p>
                          <p>
                            <strong>Cáncer de piel (melanoma maligno)</strong>
                            <br />
                            El cáncer de piel o melanoma maligno, parece estar
                            causado por el deterioro de la estructura del ADN de
                            las células de la piel que están expuestas al sol -
                            apenas un 5% de los carcinomas aparece en áreas de
                            la piel que no se exponen al sol. El deterioro de
                            estas células de la piel provoca el crecimiento de
                            células degeneradas que, a su vez, causan cáncer. En
                            Europa, el cáncer de piel es más frecuente en el
                            norte, donde la epidermis de la población tiende a
                            contener menos pigmentación.
                          </p>
                          <p> </p>
                          <h2>EFECTOS ACCIDENTALES</h2>
                          <p>
                            <strong>Intolerancias solares</strong>
                            <br />
                            El tipo más común de erupción de la piel provocada
                            por el sol es la leve erupción benigna de verano
                            (BSLE). Es muy frecuente entre las mujeres jóvenes,
                            en las que la BSLE se manifiesta en forma de
                            pequeñas e irritantes erupciones en el cuello, la
                            parte superior del pecho y la parte posterior de los
                            antebrazos. Estos síntomas aparecen en las horas
                            siguientes a una exposición intensa al sol y
                            desaparecen al cabo de unos días.
                          </p>
                          <div id="mountain" className=" ccm-block-styles">
                            <h2>SOL DE MONTAÑA</h2>
                            <p>
                              <strong>
                                ¿Por qué la piel necesita cuidados especiales a
                                gran altitud?
                              </strong>
                            </p>
                            <p>
                              <strong>
                                <br />
                              </strong>
                            </p>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_01_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Por cada <span className="orange">1,000</span>{" "}
                                metros que subimos, la intensidad de los rayos
                                solares aumenta hasta un{" "}
                                <span className="orange">12%</span>
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_02_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                La nieve recién caída refleja los rayos
                                ultravioleta del sol{" "}
                                <span className="orange">cinco veces</span> más
                                que la arena seca de la playa
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_03_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Hasta el <span className="orange">80%</span> de
                                la radiación solar puede traspasar una capa
                                ligera de nubes
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_04_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                El frío, el viento y el aire seco pueden reducir
                                el nivel de hidratación de la piel en un{" "}
                                <span className="orange">13%</span>
                              </p>
                              <p className="footNote">
                                Fuentes: Índice UV solar mundial, OMS y
                                evaluación clínica del nivel de hidratación, 12
                                personas
                              </p>
                            </div>
                          </div>
                          <div id="HTMLBlock891" className="HTMLBlock">
                            <div
                              style={{
                                clear: "both"
                              }}
                            />
                          </div>
                          <p>
                            <strong>
                              El resultado
                              <br />
                            </strong>
                            La piel se deshidrata con facilidad. Se estresa a
                            causa del sol y se vuelve roja y tensa. Para
                            defender la piel contra el frío, el viento y el sol
                            intenso se necesita un determinado tipo de
                            protección.
                          </p>
                          <p>
                            <strong>
                              Utilice la protección solar adecuada
                              <br />
                            </strong>
                            En las montañas, su piel necesita una protección
                            específica. Por eso es mejor utilizar una protección
                            solar que le ayude a defender su piel contra el
                            frío, el viento y el sol intenso. PIZ BUIN
                            <sup>®</sup> MOUNTAIN le ayuda a proteger su piel
                            cuando está a gran altitud en la montaña.
                          </p>
                          <p>
                            Por favor, consulte nuestros consejos generales para
                            la aplicación segura y eficaz de la protección
                            solar.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">SIEMPRE SEGURO BAJO EL SOL</h2>
                        <p className="toggle__sub-title">
                          Los productos de protección solar PIZ BUIN<sup>®</sup>{" "}
                          le proporcionan la protección solar que necesita,
                          pero… ¿qué más puede hacer para garantizar la máxima
                          seguridad bajo el sol?{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>Protección solar</h2>
                          <p>
                            <strong>
                              ¿Cómo funcionan los filtros solares?
                              <br />
                            </strong>
                            <br />
                            La tecnología más importante utilizada en todos los
                            fotoprotectores es el filtro UV. Los filtros UV
                            pueden ser tanto químicos como físicos.
                          </p>
                          <p> </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/f47522e78906ab93bd6746d5c4ffa028_f95.png"
                              alt="kts_img_640_chemical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <p> </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Filtro químico
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Los filtros químicos absorben los rayos
                            ultravioletas (UV) y los convierten en rayos con
                            mayor longitud de onda, que son menos perjudiciales
                            para la piel.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/3abb2aaf80e1f6d050098a8ca6618a7a_f96.png"
                              alt="kts_img_640_psysical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Filtro físico
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Los filtros físicos reflejan, transmiten o absorben
                            parcialmente la luz y pueden proteger tanto de los
                            rayos UVA como UVB. Los dos filtros físicos más
                            comunes son el dióxido de titanio y el óxido de
                            zinc.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <h2>
                            ¿Qué significa el Factor de Protección Solar (SPF)?
                          </h2>
                          <p>
                            El factor de protección solar (SPF) especificado en
                            el envase del protector solar indica el nivel de
                            protección que ofrece contra los rayos UVB.
                          </p>
                          <p> </p>
                          <p>
                            En general, cuanto mayor sea el número SPF, mayor
                            será la protección. Por lo tanto, un SPF 6 ó 10
                            ofrece una protección baja; un SPF 15, 20 ó 25
                            ofrece una protección media; un SPF 30 ó 50 ofrece
                            una protección alta; y un SPF 50+ ofrece una
                            protección muy alta. Esto se mide siguiendo un test
                            estandarizado.
                          </p>
                          <p> </p>
                          <p>
                            El número SPF indica cuanto tiempo puede exponerse
                            al sol sin riesgo de quemarse. Funciona calculando
                            los minutos que normalmente tardaría su piel en
                            empezar a quemarse si no llevara protección alguna
                            (por ejemplo 15 minutos). A continuación, se
                            multiplica esa cifra por el número SPF (en este
                            caso, factor 6). Puesto que 15x6 = 90, cuanto antes
                            de que empiece a quemarse. Dicho de otro modo,
                            cuanto más alto sea el SPF, mayor será el tiempo de
                            protección. Pero recuerde que esto son valores
                            teóricos. En realidad el tiempo depende, entre otros
                            factores, de su fototipo de piel y de la intensidad
                            de los rayos ultravioleta en cada sitio.
                          </p>
                          <p> </p>
                          <p>
                            Para elegir el SPF correcto, debe tener en cuenta
                            varios aspectos. El más importante es el fototipo de
                            su piel que incluye: el tono antes de exponerse al
                            sol, el color del cabello y de los ojos, y otros
                            elementos. También es necesario pensar dónde va a ir
                            de vacaciones y en qué época del año.
                          </p>
                          <h2> </h2>
                          <h2>¿Qué significa “protección fotoestable”?</h2>
                          <p>
                            Para proteger la piel, los protectores solares usan
                            filtros UV que absorben la radiación solar. Cuando
                            un protector solar se expone a la radiación, la
                            estructura química de los filtros UV se puede
                            alterar, rebajando su capacidad de protección.
                          </p>
                          <p> </p>
                          <p>
                            Un filtro solar fotoestable es capaz de resistir
                            mejor los efectos de la exposición a los rayos UV,
                            por lo que mantiene su nivel de protección.
                          </p>
                          <p> </p>
                          <p>
                            Todos los productos de protección solar PIZ BUIN
                            <sup>®</sup> ofrecen protección fotoestable.
                          </p>
                          <h2> </h2>
                          <h2>
                            ¿Qué significa “protección de amplio espectro”?
                          </h2>
                          <p>
                            Un protector solar con protección de amplio espectro
                            significa que tiene la capacidad de proteger contra
                            los efectos nocivos tanto de los rayos UVA
                            (causantes del envejecimiento) como de los rayos UVB
                            (causantes de quemaduras). Para que un producto de
                            protección solar sea clasificado como de protección
                            de amplio espectro, debe absorber o reflejar al
                            menos un 90% de la radiación UV con una longitud de
                            onda de entre 290 y 400 nanómetros (nm).
                          </p>
                          <p> </p>
                          <p>
                            Todos los protectores solares PIZ BUIN<sup>®</sup>{" "}
                            ofrecen protección de amplio espectro.
                          </p>
                          <h2> </h2>
                          <h2>Resistencia al agua</h2>
                          <p>
                            Es importante que el protector solar sea resistente
                            al agua, ya que la radiación UV sigue siendo un 40%
                            tan intensa a medio metro bajo el agua como en la
                            superficie. También debe proteger la parte de su
                            cuerpo que quede fuera del agua, debido a que ésta
                            refleja un 25% de radiación UV y puede aumentar sus
                            efectos nocivos.
                          </p>
                          <p> </p>
                          <p>
                            Prácticamente todos los productos de protección
                            solar PIZ BUIN<sup>®</sup> son resistentes o
                            extra-resistentes al agua, como por ejemplo nuestro
                            nuevo PIZ BUIN WET SKIN<sup>®</sup> Spray Solar
                            Transparente.
                          </p>
                          <p> </p>
                          <p>
                            *Los siguientes productos no son resistentes al
                            agua: Spray Solar Allergy SPF30, Loción Bronceadora
                            Bronze, Spray Bronceador Bronze, Aceite Seco
                            Bronceador Bronze, Protector Labial Mountain SPF30.
                          </p>
                          <h2> </h2>
                          <h2>
                            Consejos para disfrutar del sol de forma segura
                          </h2>
                          <p>
                            A menudo, ir de vacaciones significa exponerse al
                            sol por primera vez tras un largo y frío invierno
                            protegido con ropas de abrigo. Es importante
                            dedicarle más atención a su piel antes de salir y
                            asegurarse de que está lista para ese cambio tan
                            drástico.
                          </p>
                          <h3> </h3>
                          <p>
                            <strong>
                              Consejos para una aplicación correcta y eficaz del
                              protector solar y una adecuada exposición al sol:
                            </strong>
                          </p>
                          <ol>
                            <li>
                              <span>
                                Aplique el protector solar al menos 20 minutos
                                antes de exponerse al sol para permitir su
                                máxima absorción y protección.
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Asegúrese de aplicarse suficiente protector
                                solar. Una cantidad insuficiente reduce el nivel
                                de protección de manera significativa. Dedique
                                más atención a las zonas más sensibles como
                                orejas, nariz, hombros, escote y cuello.
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                No descuide las zonas que resultan difíciles de
                                alcanzar o fáciles de olvidar, como las orejas y
                                los pies o la parte superior de la espalda.
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Vuelva a aplicarse el protector solar cada 2
                                horas. Hágalo con más frecuencia después de
                                bañarse, sudar o secarse con una tolla.
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Intente evitar el intenso sol del mediodía,
                                entre las 11 y las 15, durante los meses de
                                verano o cuando esté de vacaciones en países
                                tropicales.
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Si toma alguna medicación, consulte a su médico
                                o farmacéutico para exponerse al sol con
                                seguridad. Algunos medicamentos pueden provocar
                                que la piel sea más sensible a los rayos
                                solares.
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                La exposición prolongada al sol puede poner en
                                peligro su salud, así que evite permanecer bajo
                                el sol demasiado tiempo, incluso si usa
                                protector solar.
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Proteja a los niños. Son especialmente
                                vulnerables a los efectos de la radiación UV, ya
                                que acostumbran a pasar más tiempo al aire libre
                                que los adultos. Los padres deben poner especial
                                atención en protegerlos del sol utilizando ropa
                                adecuada, gorras, gafas de sol y protectores
                                solares. Mantener a los bebés y a los niños más
                                pequeños alejados de la luz solar directa.
                              </span>
                            </li>
                          </ol>
                          <h2> </h2>
                          <h2>
                            <a name="skintypes" />
                            Tipos de piel
                          </h2>
                          <p>
                            Los diferentes tipos de piel reaccionan al sol de
                            distinta forma, de manera que el tipo de piel que
                            tenga influye en cuanto tiempo puede estar al sol y
                            en el tipo de protector solar que necesita.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">PREGUNTAS FRECUENTES</h2>
                        <p className="toggle__sub-title">
                          ¿Qué más debería saber a la hora de usar los productos
                          de protección solar de PIZ BUIN<sup>®</sup>?{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>
                            ¿Utilizar un protector solar evita que me ponga
                            moreno?
                          </h2>
                          <p>
                            Todo lo contrario. Aunque su piel cambie de color
                            más despacio si usa protector solar, el bronceado
                            obtenido es mejor y dura más tiempo porque el
                            protector solar evita que su piel se queme y se
                            descame. Algunos productos PIZ BUIN<sup>®</sup>{" "}
                            incluso contienen ingredientes especiales que
                            estimulan la producción natural y la distribución
                            uniforme sobre la piel de los pigmentos responsables
                            del bronceado.
                          </p>
                          <p> </p>
                          <h2>
                            ¿Cuánto tiempo dura un protector solar antes de
                            caducar?
                          </h2>
                          <p>
                            Depende de si el envase está abierto o no. Si no se
                            ha abierto, no hay ningún problema. Todos los
                            protectores solares PIZ BUIN<sup>®</sup> han sido
                            probados de acuerdo con la legislación de la UE y
                            tienen una vida útil de más de 30 meses. Si el
                            envase ha permanecido abierto durante más de 12
                            meses, es necesario comprar un producto nuevo.
                          </p>
                          <p> </p>
                          <h2>
                            ¿Puedo rebajar el índice de SPF a medida que me
                            acostumbro al sol? ¿A qué ritmo?
                          </h2>
                          <p>
                            Puede rebajar el índice de protección gradualmente
                            durante las vacaciones, tan pronto como su piel haya
                            producido su propio sistema de defensa: el
                            bronceado. Tras unos días de protección efectiva,
                            pruebe a pasar de un nivel de protección alto a un
                            nivel de protección medio. Pero recuerde que la piel
                            bronceada también necesita filtros solares, puesto
                            que el bronceado por sí solo no proporciona
                            suficiente protección contra la radiación UV. Tenga
                            en cuenta, además, que el índice SPF que necesita
                            depende en gran medida de su fototipo de piel y de
                            la intensidad de los rayos UV del lugar en el que se
                            encuentra.
                          </p>
                          <p> </p>
                          <p>
                            Si no está seguro respecto al SPF correcto para su
                            protector solar, escoja siempre el más alto.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\nif(document.location.hash != ''){\nvar anchor = document.location.hash;\n$(\"#container_thesun\").show();\nt = $(anchor);\n$('html,body').animate({scrollTop: t.offset().top},'slow');\n}\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
